<template>
  <div></div>
</template>

<script>
import { useAuthStore } from '../../store/auth';

export default {
  name: 'Auth#Logout',
  mounted() {
    const auth = useAuthStore();
    auth.logout();

    this.$router.push("/");
  }
}
</script>
